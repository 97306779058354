import { EnvironmentConfig } from '../../../../_shared/_models/environment-config';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApplicationConfig } from '../../../../_shared/_models/application-config';

EnvironmentConfig
@Injectable()
export class EnvironmentSpecificService {

  public envSpecific: EnvironmentConfig;
  public envSpecificNull: EnvironmentConfig = null;
  public appSpecific: ApplicationConfig;
  public appSpecificNull: ApplicationConfig = null;

  constructor(private http: HttpClient) {
  }

  public loadEnvironment(): Observable<EnvironmentConfig> {
    // Only want to do this once - if root page is revisited, it calls this again.
    if (this.envSpecific === null || this.envSpecific === undefined) {
      this.envSpecific = new EnvironmentConfig();
      this.envSpecific.APP_LOGGING_ENABLED = `${environment.APP_LOGGING_ENABLED}` == "true" ? true : false;
      this.envSpecific.CONSOLE_LOGGING_ENABLED = `${environment.CONSOLE_LOGGING_ENABLED}` == "true" ? true : false;
      this.envSpecific.SSO_REDIRECT_URI = `${environment.SSO_REDIRECT_URI}`;
      this.envSpecific.SSO_POST_LOGOUT_URI = `${environment.SSO_POST_LOGOUT_URI}`;
      this.envSpecific.REPO_LAUNCH_URL = `${environment.REPO_LAUNCH_URL}`;
      this.envSpecific.ENABLED_FEATURES = JSON.parse(JSON.stringify(`${environment.ENABLED_FEATURES}`));
      this.envSpecific.AUTH_HUB_SSO_URL = `${environment.AUTH_HUB_SSO_URL}`;
      this.envSpecific.SSO_LOGOUT_REDIRECT_URI = `${environment.SSO_LOGOUT_REDIRECT_URI}`;
      this.envSpecific.GATEWAY_API_BASE_URL = `${environment.GATEWAY_API_BASE_URL}`;
      this.envSpecific.MFA_API_BASE_URL = `${environment.MFA_API_BASE_URL}`;
      this.envSpecific.IDLE_SESSION = parseInt(`${environment.IDLE_SESSION}`);
      this.envSpecific.PRODUCTION = `${environment.production}` == "true" ? true : false;
      this.envSpecific.TIME_OUT_SESSION = parseInt(`${environment.TIME_OUT_SESSION}`);
      this.envSpecific.MFA_AUTH_COOKIE_EXPIRATION_DAYS = parseInt(`${environment.MFA_AUTH_COOKIE_EXPIRATION_DAYS}`);
      this.envSpecific.ENABLED_FEATURES = JSON.parse(JSON.stringify(`${environment.ENABLED_FEATURES}`));
      this.envSpecific.SSO = `${environment.SSO}` == "true" ? true : false;
      this.envSpecific.DEV_CONSOLE_ENABLED = `${environment.DEV_CONSOLE_ENABLED}` == "true" ? true : false;
      this.envSpecific.MFA_APP_ID = `${environment.MFA_APP_ID}`;
      this.envSpecific.CLIENT_ID = `${environment.CLIENT_ID}`;
      this.envSpecific.WEB_EAM_LOGOUT_URL = `${environment.WEB_EAM_LOGOUT_URL}`;

    }

    return of(this.envSpecific);

  }

  public loadApplicationConfig(): ApplicationConfig {

    if (this.appSpecific === null || this.appSpecific === undefined) {
      this.appSpecific = new ApplicationConfig();
      this.appSpecific.SITEID = 1;
      this.appSpecific.DISABLED_FEATURES = "[]";
      this.appSpecific.DISABLED_ROUTES = `${environment.SSO}` == "true" ? ["/login", "/registration", "/forgotpassword", "/forgotpassword /change"] : ["/registration", "/forgotpassword", "/forgotpassword /change"];
      this.appSpecific.TENANT = 'USA';
      this.appSpecific.BRAND = "BMW";
      this.appSpecific.MARKET = "USA";
      this.appSpecific.CLIENT_APP_ID = "NGD";
      this.appSpecific.SYSTEM = "MyBMW";
      this.appSpecific.IMG_BG_MOBILE_SRC = "assets/images/login-bg-mobile.jpg";

    }

    return this.appSpecific;
  }
}
