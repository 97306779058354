import { Component, OnInit, Renderer2, Inject, Injector, ViewChild, HostListener, ViewEncapsulation } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-cm-header',
  templateUrl: './cm-header.component.html',
  styleUrls: ['./cm-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CmHeaderComponent implements OnInit {
  public navIsFixed: boolean;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(
    @Inject(DOCUMENT) private document: Document) {

  }

  ngOnInit() {
    
  }


  @HostListener("window:scroll", ['$event'])
  onWindowScroll(event) {
    if (this.trigger) {
      this.trigger.closeMenu();
    }
    var navbar = document.getElementById("navbar");
    if (navbar) {
      var sticky = navbar.offsetTop;

      let position = window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
      if (position > sticky) {
        this.navIsFixed = true;
      } else {
        this.navIsFixed = false;
      }
    }
  }

}
