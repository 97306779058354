import { Injectable } from '@angular/core';

@Injectable()
export class MaskService {
  public getMaskedEmail(email: string): string {
    if (email && email.length > 0) {
      let beforePart = email.substring(0, email.indexOf('@'));
      let afterPart = email.substring(email.indexOf('@'));
      let beforeMaskPart = beforePart.substring(0, 4);
      let afterMaskPartLength = beforePart.substring(4).length;
      let afterMaskPart = Array(afterMaskPartLength + 1).join("*");

      return beforeMaskPart + afterMaskPart + afterPart;
    }
    return "";
  }
}

