import { User } from './user.model';

export class LoginStatus {
    error: boolean;
    errortype: LoginErrorType;
    currentUser: User;
}

export enum LoginErrorType {
    AccountLocked,
    AccountExpired,
    AccountNotActivated,
    AuthenticationFailed,
    GenericServerError
}
