export const environment = {
  production: true,
  ENVIRONMENT_CODE: "DEV",
  GATEWAY_API_BASE_URL: "https://devb2b.bmwfs.com",
  MFA_API_BASE_URL: "https://devb2b.bmwfs.com/exp-multifactauth-web-api/v1",
  IDLE_SESSION: 18,
  TIME_OUT_SESSION: 1,
  MFA_AUTH_COOKIE_EXPIRATION_DAYS: 90,
  SSO_REDIRECT_URI: "https://devb2b.bmwfs.com/sso",//"https://localhost:4200/sso"
  SSO_POST_LOGOUT_URI: "https://devb2b.bmwfs.com",
  AUTH_HUB_SSO_URL: "https://auth-i.bmw.com/auth/oauth2/realms/root/realms/internetb2x/authorize",//"https://auth-i.bmwgroup.net/auth/oauth2/realms/root/realms/internetb2x/authorize",
  REPO_LAUNCH_URL: "https://qsense-fsamericas-dev-b2b.bmw.com/fs",
  SSO_LOGOUT_REDIRECT_URI: "https://devb2b.bmwfs.com",
  CONSOLE_LOGGING_ENABLED: true,
  APP_LOGGING_ENABLED: true,
  DEV_CONSOLE_ENABLED: true,
  ENABLED_FEATURES: ["MAP"],
  SSO: true,
  MFA_APP_ID: 'cca52826-9a71-49b4-bcbc-92065fd3b462',
  CLIENT_ID: '4772b2a9-8d1e-4108-9e08-44d6905dd31a',
  WEB_EAM_LOGOUT_URL: 'https://auth-i.bmw.com/auth/oauth2/realms/root/realms/internetb2x/connect/endSession'
};
