import { Injectable, Injector } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IAppState } from "../store/app.store";
import { UrlBuilderService } from "./url-builder.service";
import { Store } from '@ngrx/store';
import { EnvironmentConfig } from "../../_models/environment-config";

export const route = {
  empty: '',
  home: "home",
  sso: "sso",
  error: "error",
  twoFactorAuth: "authentication",

}


export enum routes {
  home = <any>"home",
  sso = <any>"sso",
  error = <any>"error",
  twoFactorAuth = <any>"twoFactorAuth",

}

@Injectable()
export class RouterService {
  router: Router;
  activatedRoute: ActivatedRoute;
  public envConfig: EnvironmentConfig;

  constructor(private injector: Injector,
    public store: Store<IAppState>,
    private urlBuilderService: UrlBuilderService
  ) {
  }

  public navigateToLogout(idToken: string) {
    this.store.select(state => state.EnvironmentConfig).subscribe(x => this.envConfig = x);
    if (this.activatedRoute == undefined) { this.activatedRoute = this.injector.get(ActivatedRoute); }
    let WebEamExternalUrl = this.urlBuilderService.getWebEamLogoutRedirectUrl(idToken);
    let webEamLogoutUrl = this.envConfig.WEB_EAM_LOGOUT_URL;
    if (WebEamExternalUrl.includes(webEamLogoutUrl))
      window.location.href = WebEamExternalUrl;
    else
      this.navigateToErrorPage();
  }

  public navigateToExternalUrl(url: string) {
    window.location.href = url;
  }

  public navigateToErrorPage() {
    this.navigateTo(routes.error);
  }

  public navigateTo(page: routes, parameters?: object, fragmentValue?: string): void {
    if (this.router == undefined) { this.router = this.injector.get(Router); }
    if (parameters) {
      if (fragmentValue) {
        this.router.navigate([route[page]], { queryParams: parameters, fragment: fragmentValue });
      }
      else {
        this.router.navigate([route[page]], { queryParams: parameters });
      }
    }
    else {
      if (fragmentValue) {
        this.router.navigate([route[page]], { fragment: fragmentValue });
      }
      else {
        this.router.navigate([route[page]]);
      }
    }
  }

  public navigateToTarget(page?: string, paramsObject?: object): void {
    //if target page is requested, nav to the target with deeplink in querystring (used by guards), else nav to deeplink (used by components)
    if (this.router == undefined) { this.router = this.injector.get(Router); }
    if (page && page != "undefined") {
      if (page.indexOf('/') !== -1) {
        this.router.navigateByUrl(page);
      }
      else {
        if (paramsObject) {
          this.router.navigate([page], { queryParams: paramsObject });
        }
        else {
          this.router.navigate([page]);
        }

      }
    }
    else {
      this.navigateToHome();
    }

  }

  public navigateToHome(fragmentValue?: string) {
    if (fragmentValue) {
      this.navigateTo(routes.home, null, fragmentValue);
    }
    else {
      this.navigateTo(routes.home);
    }
  }

  public getUrl(): any {
    if (this.router == undefined) { this.router = this.injector.get(Router); }
    return this.router.url;
  }

  public getRouterEvents(): any {
    if (this.router == undefined) { this.router = this.injector.get(Router); }
    return this.router.events;
  }

  public getRouterSnapshotUrl(): any {
    if (this.router == undefined) { this.router = this.injector.get(Router); }
    return this.router.routerState.snapshot.url;
  }
}

