import { Injectable } from '@angular/core';
import { StorageType } from './../../_models/storagetype.model';

@Injectable()
export class StorageService
{
    public getItem(key: string, stype : StorageType): string
    {
        if (stype == StorageType.local)
        {
            return localStorage.getItem(key);
        }else
        {
            return sessionStorage.getItem(key);
        }
    }

    public setItem(key: string, data: string, stype:StorageType) 
    {
        if (stype == StorageType.local) {
            localStorage.setItem(key, data);
        } else {
            sessionStorage.setItem(key, data);
        }
    }

    public removeItem(key: string, stype: StorageType) {
        if (stype == StorageType.local) {
            localStorage.removeItem(key);
        } else {
            sessionStorage.removeItem(key);
        }
    }
}

