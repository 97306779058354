export class ApplicationConfig {
  SITEID: number;
  TENANT: string;
  BRAND: string;
  DISABLED_FEATURES: string;
  DISABLED_ROUTES: string[];
  CLIENT_APP_ID: string;
  SYSTEM: string;
  IMG_BG_MOBILE_SRC: string;
  MARKET: string;
}
