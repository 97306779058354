// import { routes } from "./_helper-services/router.service";
import { Subject } from 'rxjs';

export class Constants {

  public static USER_DATA: string = "User_Data";
  public static EMPTY = "";
  public static ResetErrors = 'ResetErrors'
  public static UnknownError = 'UnknownError'
  public static MyAccount = 'MyAccount'

  //SSO 
  public static SsoCodeParam = "code";
  public static SsoStateParam = "state";
  public static SsoAuthenticateUserGrantType: string = "authorization_code";
  public static SsoAuthHubRouteScopeParameterName = "scope";
  public static SsoAuthHubRouteScopeParameterValue = "openid profile email phone";
  public static SsoAuthHubRouteResponseTypeParameterName = "response_type";
  public static SsoAuthHubRouteResponseTypeParameterValue = "code";
  public static SsoAuthHubRouteClientIdParameterName = "client_id";
  public static SsoAuthHubRouteRedirectUriParameterName = "redirect_uri";
  public static SsoAuthIdToken = "id_token_hint";
  public static SsoAuthPostLogoutUri = "post_logout_redirect_uri";
  public static SsoAuthHubRouteStateParameterName = "state";
  public static SsoAuthHubLogoutRoute = "/connect/endSession";
  public static SsoAuthorize = "/authorize";
  public static SsoAuthHubRouteLogoutUriParameterName = "logout_uri";
  public static SsoAuthHubManageSettingsRoute = "/managesettings";
  public static MockGatewayToken = "mock-gatewayToken";
  public static PreviousUrl = "previousUrl"
  public static WebEAMProviderKey = "5";

  //Url Builder
  public static UrlParametersPrefix = "?";
  public static UrlParametersConcat = "&";
  public static UrlParameterNameAndValueConcat = "=";
  public static LanguageParameterName = "language";
  public static LanguageConcat = "-";

  public static Empty: string = "Empty";
  public static ForwardSlash = "/";

  //regular expression
  public static safariTimeStampReplace = 'T'
  public static whiteSpaceString = ' ';

  //Events
  public static logOutEvent: Subject<number> = new Subject<number>();

  //Other
  public static States: string[] = ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"];

  public static getYears(startYear: number, endYear: number): string[] {
    let years: string[] = [];
    while (startYear <= endYear) {
      years.push(endYear.toString());
      endYear = endYear - 1;
    }
    return years;
  }

  //Scroll to top
  public static ScrollToTopDelay: number = 50;

  //log constants
  public static ServerLogLevel: string = "ServerLogLevel";

  // Authentication
  public static MFA: string = "MFA";

  //MFA
  public static MfaEmailAddress: string = "EmailAddress";
  public static MfaEmail: string = "Email";
  public static MfaName: string = "mfa";
  public static MfaChallengeValidationCookie: string = "x-mfa-cookie";
}

export class HTMLConstants {
  public static getWhiteSpaces(numberOfSpaces: number): string {
    let whiteSpaces: string = '';
    while (numberOfSpaces >= 1) {
      whiteSpaces = whiteSpaces + '\xa0';
      numberOfSpaces--;
    }
    return whiteSpaces;
  }

  public static removeExtraSpaces(inputString: string, spacesToRemove: number): string {
    let whiteSpaces: string = '';
    while (spacesToRemove >= 1) {
      whiteSpaces = whiteSpaces + '\xa0';
      spacesToRemove--;
    }
    return inputString.replace(whiteSpaces, '\xa0');
  }

}
