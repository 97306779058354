import { Injectable, EventEmitter } from '@angular/core';
import { IAppState } from '../store/app.store';
import { StorageType } from './../../_models/storagetype.model';
import { StorageService } from './storage.service';
import { User } from './../../_models/user.model';
import { ApplicationConfigActions } from '../store/reducers/application-config.reducer';
import { ApplicationConfig } from '../../_models/application-config';
import { EnvironmentConfig } from '../../_models/environment-config';
import { EnvironmentConfigActions } from '../store/reducers/environment-config.reducer';
import { Constants } from './../constants';
import { RouterService } from './router.service';
import { Idle } from '@ng-idle/core';
import { Store } from '@ngrx/store';

@Injectable()
export class UserService {
  private currentUser: User;
  public userSuccessfullyLoggedOut: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private idle: Idle,
    private applicationConfigActions: ApplicationConfigActions,
    private environmentConfigActions: EnvironmentConfigActions,
    private store: Store<IAppState>,
    private routerService: RouterService,
    private storageService: StorageService
  ) {}

  setCurrentUser(data: User) {
    this.currentUser = data;
    this.storageService.setItem(
      Constants.USER_DATA,
      JSON.stringify(this.currentUser),
      StorageType.session
    );
  }

  clearSession() {
    this.currentUser = null;
    this.storageService.removeItem(Constants.USER_DATA, StorageType.session);
  }

  isLoggedIn(): boolean {
    this.currentUser = this.getUserFromSession();
    return this.currentUser !== null && this.currentUser !== undefined;
  }

  isAuthenticated(): boolean {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.isAuthenticatedUser;
    } else {
      return false;
    }
  }

  hasCheckedClientDeviceToken(): boolean {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.deviceTokenChecked;
    } else {
      return false;
    }
  }

  public setDeviceTokenChecked(checked: boolean): void {
    this.currentUser = this.getUserFromSession();
    this.currentUser.deviceTokenChecked = checked;
    this.setCurrentUser(this.currentUser);
  }

  public getUserFromSession(): User {
    let sessionValue = this.storageService.getItem(
      Constants.USER_DATA,
      StorageType.session
    );

    if (sessionValue) {
      let sessionUser: User = JSON.parse(sessionValue);

      if (sessionUser) {
        // logged in so return true
        return sessionUser;
      }
    }
    return null;
  }

  public getUserId(): string {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.userId;
    } else {
      return '';
    }
  }

  public getUserBearerToken(): string {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.bearerToken;
    } else {
      return '';
    }
  }

  public getUserBearerTokenExpireTime(): number {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.tokenExpiresIn;
    } else {
      return 0;
    }
  }

  public getUserRefreshToken(): string {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.refreshToken;
    } else {
      return '';
    }
  }

  public resetBearerToken(
    accessToken: string,
    tokenExpiresIn: number,
    refreshToken: string
  ): void {
    this.currentUser = this.getUserFromSession();
    if (this.currentUser !== null) {
      this.currentUser.bearerToken = accessToken;
      this.currentUser.tokenExpiresIn = tokenExpiresIn;
      this.currentUser.refreshToken = refreshToken;
      this.setCurrentUser(this.currentUser);
    }
  }

  public setEnvironmentConfig(_environmentConfig: EnvironmentConfig): void {
    this.store.dispatch(
      this.environmentConfigActions.setEnvironmentConfig(_environmentConfig)
    );
  }

  public setApplicationConfig(_applicationConfig: ApplicationConfig): void {
    this.store.dispatch(
      this.applicationConfigActions.setApplicationConfig(_applicationConfig)
    );
  }

  public getUserName(): string {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.userName;
    } else {
      return '';
    }
  }

  public getFirstName(): string {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.firstName;
    } else {
      return '';
    }
  }
  public getLastName(): string {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.lastName;
    } else {
      return '';
    }
  }
  public setUserName(firstName: string, lastName: string) {
    this.currentUser = this.getUserFromSession();
    if (this.currentUser) {
      this.currentUser.firstName = firstName;
      this.currentUser.lastName = lastName;
      this.setCurrentUser(this.currentUser);
    }
  }
  public getCustomerNumber(): number {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.customerNumber;
    } else {
      return null;
    }
  }

  public setJwToken(jwToken: string) {
    this.currentUser = this.getUserFromSession();
    if (this.currentUser) {
      this.currentUser.jwToken = jwToken;
      this.setCurrentUser(this.currentUser);
    }
  }

  public getJwToken(): string {
    this.currentUser = this.getUserFromSession();
    if (this.currentUser) {
      return this.currentUser.jwToken;
    } else {
      return Constants.EMPTY;
    }
  }

  public setApplicationId(applicationId: string): void {
    let applicationConfig: ApplicationConfig;
    this.store.select(state => state.ApplicationConfig).subscribe(x => applicationConfig = x);
    applicationConfig.CLIENT_APP_ID = applicationId;
    this.setApplicationConfig(applicationConfig);
  }

  public logout(): void {
    this.currentUser = this.getUserFromSession();
    let token = this.currentUser.idToken === null ? '' : this.currentUser.idToken;
    this.clearSession();
    this.idle.stop();
    this.userSuccessfullyLoggedOut.emit(true);    
    this.routerService.navigateToLogout(token);
  }
  public getEmailAddress() {
    this.currentUser = this.getUserFromSession();
    if (this.currentUser) {
      return (this.currentUser.emailAddress != null)? this.currentUser.emailAddress.trim() : null;
    } else {
      return Constants.EMPTY;
    }
  }
}
