
import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-sub-nav-header',
  templateUrl: './sub-nav-header.component.html',
  styleUrls: ['./sub-nav-header.component.scss']
})
export class SubNavHeaderComponent implements OnInit {

  constructor(@Inject(DOCUMENT) public document,
    ) {

  }

  ngOnInit() {    
  }  

  public scrollToTop(): void {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }
}
