import { Injectable } from '@angular/core';
import { Reducer } from 'redux';
import { StringModel } from '../../../_models/string.model';
import { FSA, isError, isFSA } from 'flux-standard-action';

import { tassign } from 'tassign';


export class ParentActivityIdActions {
  static SET_PARENT_ACTIVITY_ID: string = 'SET_PARENT_ACTIVITY_ID';



  setParentActivityId(parentActivityId: string): FSA<string, string, null> {
    return {
      type: ParentActivityIdActions.SET_PARENT_ACTIVITY_ID,
      payload: parentActivityId,
      meta: null
    };
  }

}

@Injectable()
export class ParentActivityIdReducers {
  static ParentActivityIdReducer: Reducer<StringModel> = (state: StringModel = new StringModel(''), action: FSA<string , string, void>) => {
    switch (action.type) {
      case ParentActivityIdActions.SET_PARENT_ACTIVITY_ID:
        return tassign(state, new StringModel(action.payload));
      default:
        return state;
    }
  }
}
