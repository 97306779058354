import { inject } from '@angular/core';

import { UserService } from './../shared/_helper-services/user.service';
import { GuardService } from './../shared/_helper-services/guard.service';
import { Constants } from '../shared/constants';
import { MfaHelperService } from '../feature/authentication/mfa/_services/mfa-helper.service';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';

export const TwoFactorAuthenticatedGuard: CanActivateFn = (next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot) => {
    const userService = inject(UserService);
    const guardService = inject(GuardService);
    const mfaHelperService = inject(MfaHelperService);

    if (!userService.isAuthenticated()) {
      return; 
    }

    if (userService.hasCheckedClientDeviceToken()) { 
      if (mfaHelperService.isMfaCookieAvailable(Constants.MfaChallengeValidationCookie)) {
        return guardService.guardSucceded();
      } else {
        userService.logout();
      }      
     
    }
    else {
      return hasFailed(guardService);
    }

}
function hasFailed(guardService: GuardService): boolean {
  return guardService.guardFailed("authentication");
}

