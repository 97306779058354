import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { BooleanModel } from '../../../_models/boolean.model';


@Injectable()
export class DataLoadedActions
{
    static SET_DATALOADED: string = 'SET_DATALOADED';

   

    setDataLoaded(dataLoaded: boolean): FSA<string, boolean, null>{
        return {
            type: DataLoadedActions.SET_DATALOADED,
            payload: dataLoaded,
            meta: null
        };
    }

}

@Injectable()
export class DataLoadedReducers
{
    static DataLoadedReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(true), action: FSA<string, boolean, void>) => {
        switch(action.type){
            case DataLoadedActions.SET_DATALOADED:
                return tassign(state, new BooleanModel(action.payload));
            default:
                return state;
        }
    }
}
