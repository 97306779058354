import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SsoComponent } from './sso.component';
import { route } from '../../shared/_helper-services/router.service';
import { FeatureToggleGuard } from '../feature-toggle/feature-toggle.guard';


const ssoRoutes: Routes = [
    { path: route.sso, component: SsoComponent, canActivate: [FeatureToggleGuard] } 
];

@NgModule({
    imports: [
        RouterModule.forChild(ssoRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class SsoRoutingModule { }
