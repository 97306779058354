export class GenerateMultiFactorTokenStatus {
    error: boolean;
    errortype: GenerateMultiFactorTokenStatusErrorType;
    errorMessage: string;
    transactionId: string;
  }
  
  export enum GenerateMultiFactorTokenStatusErrorType {
    ResourceNotFound,
    GenericServerError
  }
  