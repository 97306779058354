import { Component, OnInit, OnDestroy, Renderer2, Inject, Injector, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { RouterService, routes } from '../../shared/_helper-services/router.service';
import { LoginService } from '../../_web-services/login.service';
import { ActivatedRoute } from '@angular/router';
import { Constants } from '../../shared/constants';
import { BaseApplicationComponent } from "../../core/basecomponent/base.component";
import { LoginStatus, LoginErrorType } from '../../_models/login-status.model';
import { UserService } from '../../shared/_helper-services/user.service';
import { UserIsTimedOutActions } from "../../shared/store/reducers/user-is-timed-out.reducer";
import { IdleService } from '../../shared/_helper-services/idle.service';
import { EnvironmentConfig } from '../../_models/environment-config';

 @Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SsoComponent extends BaseApplicationComponent<SsoComponent> implements OnInit, OnDestroy {
  public code: string;
  public state: string;
  public grantType: string;
  //public uri: string;
  public hasError: boolean;
  public errorMessage: string;
  public envConfig: EnvironmentConfig;

  constructor(
    public activatedRoute: ActivatedRoute,
    public translateService: TranslateService,
     public routerService: RouterService,
    public loginService: LoginService,
    public userService: UserService, 
    public idleService: IdleService,
    public userIsTimedOutActions: UserIsTimedOutActions,   
    renderer2: Renderer2,
     @Inject(DOCUMENT) document,
     injector: Injector,
    ) {
     super(injector, document);
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.destroy();
  }

  public init() {
    // Clear session
    this.userService.clearSession();
    this.grantType =  Constants.SsoAuthenticateUserGrantType;
    let clientAPPID: string;
    this.store.select(state => state.ApplicationConfig.CLIENT_APP_ID).subscribe(x => clientAPPID = x);
    super.getLoggerForComponent(this, clientAPPID)
      .then(rtrn => {
        this.logger = rtrn;
        this.activatedRoute
          .queryParams
          .subscribe(params => {
            this.code = params[Constants.SsoCodeParam];
            this.state = params[Constants.SsoStateParam]; 
            this.authenticateUser(this.state);
          });
      });
  }

  public destroy() {
  }

  public authenticateUser(state: string) {
    this.store.dispatch(this.userIsTimedOutActions.setUserIsTimedOut(false));
    this.userService.clearSession(); 
    this.loginService.ssoLogin(this.code, this.grantType, undefined)
      .subscribe(loginStatus => { this.postAuthentication(loginStatus, state); });
  }

  postAuthentication(loginStatus: LoginStatus, state: string) {
    if (loginStatus.error == true) {
      this.hasError = true;
      
      this.userService.clearSession();
      this.routerService.navigateToErrorPage();
    } else {
      this.hasError = false; 
      this.idleService.initIdle();  
      let decodedTargetUrl = decodeURIComponent(state);
      this.routerService.navigateToTarget(decodedTargetUrl);
    }
  }

}
