import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../constants';
import { IAppState } from "../store/app.store";
import { Language } from '../enums';
import { Languagecode } from '../enums';
import { Store } from '@ngrx/store';
import { ApplicationConfig } from '../../_models/application-config';


@Injectable()
export class LanguageService {
  storeAppConfig: ApplicationConfig;
  constructor(private translateService: TranslateService,
    private store: Store<IAppState>,
    ) { }

  public getCurrentLanguage(): string {
    return this.translateService.currentLang.substring(this.translateService.currentLang.lastIndexOf(Constants.LanguageConcat) + 1);
  }
  public getCurrentLanguageCode(): string {
        let languageCode: string;      
        switch (this.getCurrentLanguage()) {
          case Language.French: {
            languageCode = Languagecode.French;
            break;
          }
          case Language.English: {
            languageCode = Languagecode.English;
            break;
          }
          default: {
            languageCode = Languagecode.English;
            break;
          }

        }
    
    return languageCode;
  }
  public getLanguageParameter(): string {
    return Constants.LanguageParameterName + Constants.UrlParameterNameAndValueConcat + this.getCurrentLanguage();
  }
  
  public setCurrentLanguage(language: string): void {
    let tenant, brand, client_APP_ID: string;
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.storeAppConfig = x);
    tenant = this.storeAppConfig.TENANT;
    brand = this.storeAppConfig.BRAND;
    client_APP_ID = this.storeAppConfig.CLIENT_APP_ID;
    let lang = (tenant + Constants.LanguageConcat + brand + Constants.LanguageConcat + client_APP_ID + Constants.LanguageConcat + language).toLowerCase();
    if (this.translateService.langs.indexOf(lang) > -1) {
      //this.clearCachedLanguageData();
      this.translateService.use(lang);
    }
  }

  public getCurrentLang(currentLang: string): string {
      return currentLang.substring(currentLang.lastIndexOf(Constants.LanguageConcat) + 1);
  }

}


