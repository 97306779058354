import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { UserService } from '../shared/_helper-services/user.service';
import { GuardService } from '../shared/_helper-services/guard.service';
import { UrlBuilderService } from '../shared/_helper-services/url-builder.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../shared/store/app.store';


export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) => {
    const guardService = inject(GuardService);
    const store = inject(Store<IAppState>);
    const userService = inject(UserService);
    const urlBuilderService = inject(UrlBuilderService);
  
    guardService.setLanguage(state);
      if (userService.isAuthenticated())
      return guardService.guardSucceded();
      let webEamExternalUrl = urlBuilderService.getWebEamRedirectUrl(state.url);
    return guardService.guardFailed(webEamExternalUrl, true);
  
  }