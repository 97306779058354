import { Injectable } from '@angular/core';

@Injectable()
export class ValidatorService {
  
  private regexExpressions = {
    mtan: new RegExp(/^[0-9]{6}$/),
    mfa: new RegExp(/^[a-zA-Z0-9]{6,15}$/),
  }

  showError(field: any, type?: string, showErrorAtStart?: boolean, showErrorOnChange?: boolean): boolean {
    let fieldIsInvalid: boolean;
    if (type) {
      fieldIsInvalid = field && field.invalid && field.errors && field.errors[type];
    }
    else {
      fieldIsInvalid = field && field.invalid;
    }
    return showErrorAtStart ?
      fieldIsInvalid
      : showErrorOnChange ?
        fieldIsInvalid && (field.touched || field.dirty)
        : fieldIsInvalid && field.touched;
  }
 
  showRegexError(field: any, type: string, showErrorAtStart?: boolean): boolean {
    let fieldIsNotValid = field && !this.validateRegex(type, field.value);
    return showErrorAtStart ? fieldIsNotValid : fieldIsNotValid && field.touched;
  }

  private validateRegex(key: string, value: string): boolean {
    if (value) {
      let regex = this.regexExpressions[key];
      let isValid = regex ? regex.test(value) : false;
      return isValid;
    }
    return true;
  }
  
}
