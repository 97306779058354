import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { FeatureToggleService } from './feature-toggle.service';

export const FeatureToggleGuard: CanActivateFn = (next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot) => {
    const featureToggleService = inject(FeatureToggleService);
    featureToggleService.setLanguage(state);

    if (featureToggleService.isRouteEnabled(state.url)) {
      return true;
    }
    else {
      let currentUrl = state.url;
      let origUrl = state.root.queryParams.origUrl;
      featureToggleService.handleDisabledRoute(currentUrl, origUrl);

      return false;
    }

}
