import { combineReducers } from 'redux';

import { UserIsTimedOutReducers } from './reducers/user-is-timed-out.reducer';
import { BooleanModel } from '../../_models/boolean.model';
import { StringModel } from '../../_models/string.model';
import { GlobalErrorReducers } from './reducers/global-error-message.reducer';

import { UserActivityIdReducers } from './reducers/user-activityId.reducer';

import { EnvironmentConfig } from '../../_models/environment-config';
import { ApplicationConfig } from '../../_models/application-config';
import { ApplicationConfigReducers } from './reducers/application-config.reducer';
import { EnvironmentConfigReducers } from './reducers/environment-config.reducer';

import { HasLoggedInReducers } from './reducers/has-logged-in.reducer';
import { ParentActivityIdReducers } from './reducers/parent-activity-id.reducer';
import { ActionReducerMap } from '@ngrx/store';
import { DataLoadedReducers } from './reducers/dataloaded.reducer';

export interface IAppState {

  UserIsTimedOut?: BooleanModel;
  ApplicationConfig?: ApplicationConfig;
  EnvironmentConfig?: EnvironmentConfig;
  HasLoggedIn?: BooleanModel;
  ParentActivityId?: StringModel;
  UserActivityId?: StringModel;
  GlobalError?: StringModel;
  DataLoaded?: BooleanModel;
}

export const INITIAL_STATE: IAppState = {

  UserIsTimedOut: new BooleanModel(false),
  ApplicationConfig: null,
  EnvironmentConfig: null,
  HasLoggedIn: new BooleanModel(false),
  ParentActivityId: new StringModel(''),
  UserActivityId: new StringModel(''),
  DataLoaded: new BooleanModel(true),
};

export const rootReducer:ActionReducerMap<IAppState> = {
  UserIsTimedOut: UserIsTimedOutReducers.UserIsTimedOutReducer,
  ApplicationConfig: ApplicationConfigReducers.ApplicationConfigReducer,
  EnvironmentConfig: EnvironmentConfigReducers.EnvironmentConfigReducer,
  HasLoggedIn: HasLoggedInReducers.HasLoggedInReducer,
  ParentActivityId: ParentActivityIdReducers.ParentActivityIdReducer,
  UserActivityId: UserActivityIdReducers.UserActivityIdReducers,
  GlobalError: GlobalErrorReducers.GlobalErrorReducer,
  DataLoaded: DataLoadedReducers.DataLoadedReducer
};

