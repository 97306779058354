import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserInactivityDialog } from './../_components/dialogs/user-inactivity-dialog/user-inactivity-dialog.component';
import { IAppState } from "./../../shared/store/app.store";
import { Store } from '@ngrx/store';

@Injectable()
export class DialogService {
  public changeAccountDialogIsShown: boolean;
  public addAchDialogIsShown: boolean;
  public isModelOpened: boolean;

  idleDialogRef: MatDialogRef<UserInactivityDialog>;
  

  constructor(private dialog: MatDialog, 
    private store: Store<IAppState>) { }

  public openIdleDialog(): void {
    this.closeDialogsBeforeTimeout();
    this.idleDialogRef = this.dialog.open(UserInactivityDialog);

  }

  public closeIdleDialog(): void {
    if (this.idleDialogRef) {
      this.idleDialogRef.close(true);
    }
  }

  public closeDialogsBeforeTimeout(): void {   
    //todo: add any new dialogs here
  }

  
}
