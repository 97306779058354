import { Injectable } from '@angular/core';
import { IAppState } from "../store/app.store";
import { CookieService } from 'ngx-cookie-service';
import { Constants } from '../constants';
import { Store } from '@ngrx/store';

@Injectable()
export class StorageHelperService {

  constructor(
    private cookieService: CookieService,
    private store: Store<IAppState>) {
  }
  
  public getMFACookieKey(gcid: string): string{
    return `${gcid}-${Constants.MFA}`;
  }

  public getCookie(key: string): string{
    return this.cookieService.get(key);
  }

  public setCookie(key: string, value: string, expires: number | Date, path = "/", domain: string = window.location.hostname){
    let secure: boolean;
    this.store.select(state => state.EnvironmentConfig.PRODUCTION).subscribe(x => secure = x);  
    this.cookieService.set(key, value, expires, path, domain, secure);
  }

  public removeCookie(key: string) {
    let secure: boolean;
    this.store.select(state => state.EnvironmentConfig.PRODUCTION).subscribe(x => secure = x);      
    this.cookieService.delete(key, "/", window.location.hostname, secure);
  }
  
  public isCookieKeyExist(key: string): boolean {
       return this.cookieService.check(key);
  }

}