import { Observable, of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { FaultCodes } from '../shared/FaultCodes';
import { LoginServiceClient, AuthenticateUserRequest, AuthenticateUserResponse, SiteKey, ProviderKey} from '../core/gateway-api';
import { User, AppMode } from '../_models/user.model';
import { LoginStatus, LoginErrorType } from '../_models/login-status.model';
import { UserService } from '../shared/_helper-services/user.service';
import { IAppState } from "../shared/store/app.store";
import { Constants } from "../shared/constants";
import { ApplicationId } from '../shared/enums';
import { HasLoggedInActions } from '../shared/store/reducers/has-logged-in.reducer';
import { Store } from '@ngrx/store';
import { EnvironmentConfig } from '../_models/environment-config';


@Injectable()
export class LoginService {

  private currentUser: User;
  private password: string;
  private code: string;
  private siteKey: SiteKey;
  private providerKey: ProviderKey;
  private authenticateUserRequest: AuthenticateUserRequest;
  private authenticateUserResponse: AuthenticateUserResponse;
  private loginStatus: LoginStatus;
  public initialUserLoginState: EventEmitter<any> = new EventEmitter<any>();
  public storeAppConfig: EnvironmentConfig;
  constructor(
    private loginServiceClient: LoginServiceClient,
    private userService: UserService,
    private store: Store<IAppState>,
    private hasLoggedInActions: HasLoggedInActions) { }

  login(emailAddress: string, password: string): Observable<LoginStatus> {
    this.currentUser = new User(emailAddress);
    this.password = password;
    this.userService.setApplicationId(ApplicationId.Ngd.toString());
    return this.authenticateUser(false, undefined, undefined);
    
  }

  ssoLogin(code: string, grantType: string, uri: string): Observable<LoginStatus> {
    this.currentUser = new User();
    this.code = code;
    switch (grantType) {     
      case Constants.SsoAuthenticateUserGrantType:
        this.currentUser.appMode = AppMode.AuthHub
        return this.authenticateUser(true, grantType, uri);
      default:
        this.currentUser.appMode = AppMode.Default
        return this.authenticateUser(true, grantType, uri);
    }
  }
 

  private setProviderKeySiteKey(isSSO: boolean = false) {
    this.providerKey = new ProviderKey();
    this.store.select(state => state.EnvironmentConfig).subscribe(x => this.storeAppConfig = x);
    let siteId:number;
    this.store.select(state => state.ApplicationConfig.SITEID).subscribe(x => siteId = x);
    
    this.providerKey.providerId = isSSO ? Constants.WebEAMProviderKey: this.storeAppConfig.CLIENT_ID;
    this.siteKey = new SiteKey();
    this.siteKey.siteId = siteId;
  }


  private authenticateUser(isSSO: boolean, grantType: string, uri: string): Observable<LoginStatus> {
    this.authenticateUserRequest = new AuthenticateUserRequest();
    this.setProviderKeySiteKey(isSSO);
    if (isSSO) {
      this.authenticateUserRequest.code = this.code;
      this.authenticateUserRequest.grantType = grantType;
      let ssoRedirectURI: string;
      this.store.select(state => state.EnvironmentConfig.SSO_REDIRECT_URI).subscribe(x => ssoRedirectURI = x);      
      this.authenticateUserRequest.redirectUri = (uri == undefined) ? ssoRedirectURI : uri;
      this.authenticateUserRequest.username = null;
      this.authenticateUserRequest.password = null;
    }
    else {
      this.authenticateUserRequest.username = this.currentUser.userName;
      this.authenticateUserRequest.password = this.password;
    }
    this.authenticateUserRequest.siteKey = this.siteKey;
    this.authenticateUserRequest.providerKey = this.providerKey;

    this.loginStatus = new LoginStatus();

    return this.loginServiceClient.authenticateUser(this.authenticateUserRequest)
      .pipe(mergeMap((response) => { return this.afterAuthenticationPostSuccess(response); }),catchError((error: any) => {
        return this.afterAuthenticationPostFailure(error);
      }));

  }


  private afterAuthenticationPostSuccess(result: any): Observable<LoginStatus> {
    this.authenticateUserResponse = result;
    this.currentUser.isAuthenticatedUser = this.authenticateUserResponse.userEntity.mutable.isAuthenticated;
    if (this.currentUser.isAuthenticatedUser) {     
      this.currentUser.bearerToken = this.authenticateUserResponse.userEntity.mutable.token;    
      this.currentUser.tokenExpiresIn = this.authenticateUserResponse.userEntity.mutable.tokenExpiresIn;
      this.currentUser.refreshToken = this.authenticateUserResponse.userEntity.mutable.refreshToken;
      this.currentUser.userName = this.authenticateUserResponse.userEntity.mutable.username;
      this.currentUser.emailAddress = this.authenticateUserResponse.userEntity.mutable.emailAddress;
      this.currentUser.idToken = this.authenticateUserResponse.userEntity.mutable.tokenType;
      this.currentUser.userId = this.authenticateUserResponse.userEntity.key.userId;
      this.userService.setCurrentUser(this.currentUser);
      this.store.dispatch(this.hasLoggedInActions.setHasLoggedIn(true));
      this.loginStatus.error = false;
      return of(this.loginStatus);     
    } else {
      this.loginStatus.error = true;
      this.loginStatus.errortype = LoginErrorType.AuthenticationFailed;
      return of(this.loginStatus);
    }
  }


  private afterAuthenticationPostFailure(error: any): Observable<LoginStatus> {
    this.loginStatus.error = true;
    if (error.faultType) {
      switch (error.faultType) {
        case FaultCodes.BMWFSAM_Services_IdentityAndAccessManagement_UserAccountExpired_V201109_UserAccountExpiredFault:
          {
            this.loginStatus.errortype = LoginErrorType.AccountExpired;
            break;
          }
        case FaultCodes
          .BMWFSAM_Services_IdentityAndAccessManagement_UserAccountPasswordLocked_V201109_UserAccountPasswordLockedFault:
          {
            this.loginStatus.errortype = LoginErrorType.AccountLocked;
            break;
          }
        case FaultCodes.BMWFSAM_Services_IdentityAndAccessManagement_UsernameDisabled_V201109_UsernameDisabledFault:
          {
            this.loginStatus.errortype = LoginErrorType.AccountLocked;
            break;
          }
        case FaultCodes
          .BMWFSAM_Services_IdentityAndAccessManagement_UserAccountNotActivated_V201109_UserAccountNotActivatedFault:
          {
            this.loginStatus.errortype = LoginErrorType.AccountNotActivated;
            break;
          }
        default:
          { 
            this.loginStatus.errortype = LoginErrorType.AuthenticationFailed;
            break;
          }
      }
    } else {
      this.loginStatus.errortype = LoginErrorType.GenericServerError;
    }
    return of(this.loginStatus);
  }
}
