export class GenerateMfaTokenStatus {
  error: boolean;
  errortype: GenerateMfaTokenStatusErrorType;
}

export enum GenerateMfaTokenStatusErrorType {
  DataValidation,
  InvalidToken,
  ResourceNotFound,
  GenericServerError
}
