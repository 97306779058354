import { Component, HostBinding, Injector, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss', './home-bmw-theme.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HomeComponent implements OnInit {
  @HostBinding('class') @Input('class') classList: string = "page-home";
constructor(private translateService: TranslateService)
     {
    }

  ngOnInit() {
    
  }


}
