export class User {
  userName: string;
  isFsLogin: boolean;
  isChatBotAllowed: boolean;
  isAuthenticatedUser: boolean;
  userId: string;
  isTermsAccepted: boolean;
  isEsignAccepted: boolean;
  bearerToken: string;
  refreshToken: string;
  tokenExpiresIn: number;
  fsToken: string;
  idToken: string;
  deviceTokenChecked: boolean;
  customerNumber: number;
  accountNumbers: string[];
  isHomePageActivityLogged: boolean;
  isLegitimizationCompletedInCurrentSession: boolean;
  skipTour: boolean;
  appMode: AppMode;
  twoFactorAuthTransactionid: string;
  hasBeenAutoEnrolled: boolean;
  firstName: string;
  lastName: string;
  showBulletins: boolean;
  agentId: string;
  jwToken: string;
  emailAddress: string;

  constructor(username?: string) {
    this.userName = username;
    this.isFsLogin = false;
    this.isChatBotAllowed = false;
    this.isAuthenticatedUser = false;
    this.isTermsAccepted = false;
    this.isEsignAccepted = undefined;
    this.userId = null;
    this.bearerToken = null;
    this.refreshToken = null;
    this.tokenExpiresIn = null;
    this.fsToken = '';
    this.idToken = '';
    this.deviceTokenChecked = false;
    this.customerNumber = null;
    this.accountNumbers = null;
    this.isHomePageActivityLogged = false;
    this.isLegitimizationCompletedInCurrentSession = false;
    this.skipTour = false;
    this.appMode = AppMode.Default; //Default
    this.twoFactorAuthTransactionid = null;
    this.firstName = '';
    this.lastName = '';
    this.showBulletins = true;
    this.agentId = null;
    this.jwToken = null;
    this.emailAddress = null;
  }
}

export enum AppMode {
  Default = <any>"Default",
  AuthHub = <any>"AuthHub",
  ConnectedApp = <any>"ConnectedApp"
}

