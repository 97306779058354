import { Component, OnInit, Inject, Injector } from '@angular/core';
import { BaseApplicationComponent } from "../../../../_shared/core/basecomponent/base.component";
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-path-not-found',
    templateUrl: './path-not-found.component.html',
    styleUrls: ['./path-not-found.component.scss']
})
export class PathNotFoundComponent extends BaseApplicationComponent<PathNotFoundComponent> implements OnInit {

    constructor(@Inject(DOCUMENT) document,
        injector: Injector) {
        super(injector, document);
    }

    ngOnInit() {
       
    }

    public init(){
    }
}
