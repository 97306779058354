import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AppTileComponent} from './_components/app-tile/app-tile.component';
import {SubNavHeaderComponent} from './_components/sub-nav-header/sub-nav-header.component';
import {CmHeaderComponent} from './_components/cm-header/cm-header.component';
import {HomeComponent} from './home.component';
import {HomeRoutingModule} from './home-routing.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    HomeRoutingModule,
    TranslateModule  
  ],
  exports:[ AppTileComponent, HomeComponent, SubNavHeaderComponent,CmHeaderComponent],
  declarations: [AppTileComponent, HomeComponent, SubNavHeaderComponent,CmHeaderComponent],
  providers: []
})
export class HomeModule { }
