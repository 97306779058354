export enum ApplicationId {
  Ngd = <any>"NGD",
  ConnectedApp = <any>"CONAPP",
  ConnectedAppIOS = <any>"CONAPP_IOS"
}

export enum Language {
  English = "en",
  French = "fr"
}

export enum Languagecode {
  English = "enu",
  French = "fra"
}

export enum AuthenticationViewMode {
  Info = 1,
  EnterCode = 2,
  NoCode = 3,
  Remember = 4
}