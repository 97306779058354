import { Routes, RouterModule } from '@angular/router';
import { SelectivePreloadingStrategy } from '../../../_shared/core/selective-preloading-strategy';
import { PathNotFoundComponent } from './path-not-found/path-not-found.component';
import { route } from '../../../_shared/shared/_helper-services/router.service';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'sso', pathMatch: 'full', redirectTo: 'sso' },
  {
    path: route.twoFactorAuth, loadChildren: () => import('../../../_shared/feature/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'error', loadChildren: () => import('../../../_shared/shared/_components/error/error.module').then(m => m.ErrorModule)
  },
  { path: '**', pathMatch: 'full', component: PathNotFoundComponent }
];

export const rhmRouting = RouterModule.forRoot(routes,
  { preloadingStrategy: SelectivePreloadingStrategy, scrollPositionRestoration: 'top' });
