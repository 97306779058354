import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GenerateMultiFactorTokenStatus } from '../../_models/generate-multi-factor-token-status.model';
import { ErrorMessagingModel } from '../../../_shared/_models/error-messaging.model';
import { FaultCodes } from '../FaultCodes';

@Injectable()
export class ErrorMessagingService {

  constructor(
    private translateService: TranslateService) { }

  public getGenerateMultiFactorTokenErrorMessage(error: GenerateMultiFactorTokenStatus): ErrorMessagingModel {
    let errorMessagingModel = new ErrorMessagingModel();
    const messageKey = 'ngw.global.technical-error';
    const resourceNotFound = 'ngw.user-management.authentication.error.mfa.resource-not-found';
    switch (error.errortype.toString()) {
      case FaultCodes.ResourceNotFound:
       this.translateService.get(resourceNotFound).subscribe((res: string) => {
          errorMessagingModel.errorMessage = res;
        });

        break;
      default:
        this.translateService.get(messageKey).subscribe((res: string) => {
          errorMessagingModel.errorMessage = res;
        });
        break;
      }
      return errorMessagingModel;
    }

  
}

