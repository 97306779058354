import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import {AuthGuard} from '../../../../_shared/_gaurd/auth.guard';
import { TwoFactorAuthenticatedGuard } from '../../../../_shared/_gaurd/two-factor-authenticated.guard';

const homeRoutes: Routes = [
  {
    path: '',    
    component: HomeComponent,
    canActivate: [AuthGuard, TwoFactorAuthenticatedGuard],
    }  
];

@NgModule({
    imports: [
        RouterModule.forChild(homeRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class HomeRoutingModule { }
