import { Injectable } from "@angular/core";
import { MfaModel } from "../../../../_models/mfa.model";
import { UserService } from "../../../../shared/_helper-services/user.service";
import * as AES from 'crypto-js/aes';
import { StorageHelperService } from "../../../../shared/_helper-services/storage-helper.service";
import { IAppState } from "../../../../shared/store/app.store";
import { RouterService } from "../../../../../_shared/shared/_helper-services/router.service";
import { Store } from "@ngrx/store";

@Injectable()
export class MfaHelperService {

    private mfaModel: MfaModel;
    constructor(private userService: UserService,
        private store: Store<IAppState>,
        private routerService: RouterService,
        private storageHelperService: StorageHelperService) { }

    public mfaSetAuthCookie(transactionId: string): void {
        let emailAddress: string = this.userService.getEmailAddress();
        const userId = this.userService.getUserId();
        this.mfaModel = new MfaModel(emailAddress,transactionId);
        const encUserDetails = AES.encrypt(JSON.stringify(this.mfaModel), userId).toString();
        let mfaAuthCookieExpDays;
        this.store.select(state => state.EnvironmentConfig.MFA_AUTH_COOKIE_EXPIRATION_DAYS).subscribe(x => mfaAuthCookieExpDays = x);
        const msExp: number = mfaAuthCookieExpDays * 24 * 60 * 60 * 1000;       
        const newMs: number = Date.now() + msExp;
        const expTime = new Date(newMs);
        this.storageHelperService.setCookie(this.storageHelperService.getMFACookieKey(userId), encUserDetails, expTime); 
    }

    public mfaRemoveChallengeValidationCookie(key) {
        this.storageHelperService.removeCookie(key);
    }

     public isMfaCookieAvailable(key): boolean {
       return this.storageHelperService.isCookieKeyExist(key);
    }
   
}