import { Component, Inject, Injector, Renderer2 } from '@angular/core';
import { BaseApplicationComponent } from './../../../_shared/core/basecomponent/base.component';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: { '[class]': 'customClass' }
})
export class AppComponent extends BaseApplicationComponent<AppComponent>{
  title = 'b2b-portal';
  customClass = 'bmw-theme';
  constructor(injector: Injector,
       renderer2: Renderer2,
    @Inject(DOCUMENT) document) {
    super(injector, document);
   }
}
