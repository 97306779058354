import { Injectable } from '@angular/core';
import { Constants } from '../constants';


@Injectable()
export class WindowService {

  public scrollToTop(timeout?: boolean) {
    if (timeout) {
      setTimeout(function () {
        if (window.pageYOffset) {
          window.scroll(0, 0);
        }
      }, Constants.ScrollToTopDelay);
    }
    else {
      window.scroll(0, 0);
    }   
  }

}
