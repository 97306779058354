import {
  OnDestroy,
  OnInit,
  Inject,
  Injector,
  Injectable,
} from "@angular/core";
import {
  ActivityLoggingFactory,
  ActivityLogging,
} from "./features/logging/logging.factory";

import { IAppState } from "./../../shared/store/app.store";
import { DOCUMENT } from "@angular/common";

import { ActivatedRoute } from "@angular/router";
import { LanguageService } from "../../shared/_helper-services/language.service";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import { DataLoadedActions } from "../../shared/store/reducers/dataloaded.reducer";
import { BooleanModel } from "../../_models/boolean.model";

@Injectable()
export abstract class BaseApplicationComponent<T> implements OnDestroy, OnInit {
  protected logger: ActivityLogging;
  protected logFactory: ActivityLoggingFactory;
  public store: Store<IAppState>;  
  private dataLoadedActions: DataLoadedActions;
  public dataLoaded :boolean = true;
  public scrollToTop: boolean;
  private routes: ActivatedRoute;
  private langService: LanguageService;
  private transService: TranslateService;
  public locale: string;
  public dateFormat: string;
  public dateLongFormat: string;
  private langChangeSubscription: any;
  private dataLoadedSubscription;

  constructor(private injector: Injector, @Inject(DOCUMENT) public document) {
    this.logFactory = injector.get(ActivityLoggingFactory);
    this.store = injector.get(Store);
    this.routes = injector.get(ActivatedRoute);
    this.langService = injector.get(LanguageService);
    this.transService = injector.get(TranslateService);
    this.dataLoadedActions = injector.get(DataLoadedActions);
    this.routes.queryParams.subscribe((params) => {
      let language = params["language"];
      if (language != undefined) {
        this.langService.setCurrentLanguage(language);
      }
    });

    this.langChangeSubscription = this.transService.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        this.getDateAndCurrencyFormat();
      }
    );
    this.getDateAndCurrencyFormat();
    this.dataLoadedSubscription = this.store.select(state => state.DataLoaded)
    .subscribe((BooleanModel) => this.setDataLoadedOnChange(BooleanModel));

  }

  getDateAndCurrencyFormat() {
    //currency format
    this.transService.get("rhm.general.locale").subscribe((res: string) => {
      this.locale = res;
    });
    //date format
    this.transService
      .get("rhm.general.date-format")
      .subscribe((res: string) => {
        this.dateFormat = res;
      });
    this.transService
      .get("rhm.general.date-long-format")
      .subscribe((res: string) => {
        this.dateLongFormat = res;
      });
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.langChangeSubscription.unsubscribe();
    this.dataLoadedSubscription.unsubscribe();
  }

  public getLoggerForComponent(
    implementation: T,
    activityName: string = "",
    isParentActivity: boolean = false
  ) {
    return this.logFactory.getLogger<T>(
      implementation,
      activityName,
      isParentActivity
    );
  }

  public setDataLoadedOnRequest(dataLoaded: boolean) {
    this.store.dispatch(this.dataLoadedActions.setDataLoaded(dataLoaded));
    this.dataLoaded = dataLoaded;
  }

  private setDataLoadedOnChange(BooleanModel: BooleanModel) {
    this.dataLoaded = BooleanModel.booleanValue;
  }
 
}
