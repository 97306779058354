import { Component, OnInit } from '@angular/core';
import { RouterService } from '../../../../../../_shared/shared/_helper-services/router.service';
import { IAppState } from  '../../../../../../_shared/shared/store/app.store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-tile',
  templateUrl: 'app-tile.component.html',
  styleUrls: ['app-tile.component.scss'],
  host: { '[class]': 'customClass' }
})
export class AppTileComponent implements OnInit {

  customClass = 'home-tile';

  constructor(
    private store: Store<IAppState>,
    private routerService: RouterService
  ){}  

  ngOnInit() {
  }
  goToPortal(){
    let repoLanuchUrl :string;
    this.store.select(state => state.EnvironmentConfig.REPO_LAUNCH_URL).subscribe(x => repoLanuchUrl = x);
    this.routerService.navigateToExternalUrl(repoLanuchUrl);
  }

}
