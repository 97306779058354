import { Observable, catchError, mergeMap, of} from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
import { FaultCodes } from '../shared/FaultCodes';
import { GenerateMfaTokenStatus, GenerateMfaTokenStatusErrorType} from '../_models/generate-mfa-token-status.model';
import { GenerateMultiFactorTokenStatus, GenerateMultiFactorTokenStatusErrorType } from '../_models/generate-multi-factor-token-status.model';
import { IAppState } from "../shared/store/app.store";
import { LanguageService } from '../shared/_helper-services/language.service';
import { v4 as uuidv4 } from 'uuid';
import { ChallengeSubmssionRequest, ChallengeValidationRequest, Client } from '../core/mfa-token-api';
import { Constants } from '../shared/constants';
import { Store } from '@ngrx/store';



@Injectable()
export class AuthService {

  constructor(
    private client:Client,
    private store: Store<IAppState>,
    private languageService: LanguageService) { }
  
  public generateMFAToken(email: string, mfaToken: string, transactionId: string): Observable<GenerateMfaTokenStatus> {
    let mfa_app_id:string;
    this.store.select(state => state.EnvironmentConfig.MFA_APP_ID).subscribe(x => mfa_app_id = x);
    const mfaAppId: string = mfa_app_id;   
    let challengeValidationRequest= new ChallengeValidationRequest();
    challengeValidationRequest.locale = this.languageService.getCurrentLanguage().toUpperCase();
    challengeValidationRequest.mfatoken = mfaToken;
    challengeValidationRequest.primarymfafactortype= Constants.MfaEmailAddress;
    challengeValidationRequest.primarymfadeliverychannel= Constants.MfaEmail;
    challengeValidationRequest.primarymfafactorvalue= email;
    challengeValidationRequest.transactionid = transactionId;
    return this.client.challengeValidation(mfaAppId,transactionId,challengeValidationRequest)
      .pipe(mergeMap((response) => this.getMfaTokenSuccess(response)),
      catchError((response) => this.getMfaTokenError(response)));
  }

  private getMfaTokenError(error: any): Observable<GenerateMfaTokenStatus> {
    let generateMfaTokenStatus = new GenerateMfaTokenStatus();
    generateMfaTokenStatus.error = true;
    generateMfaTokenStatus.errortype = GenerateMfaTokenStatusErrorType.GenericServerError;
    if (error != undefined) {
      let response = JSON.parse(error);
      let faultType = response ? response.errorType : null;
      if (faultType) {
        switch (faultType) {
          case FaultCodes.UnAuthorized: {
            generateMfaTokenStatus.errortype = GenerateMfaTokenStatusErrorType.InvalidToken;
            break;
          } case FaultCodes.Bad_Request: {
            generateMfaTokenStatus.errortype = GenerateMfaTokenStatusErrorType.DataValidation;
            break;
          }
          case FaultCodes.ResourceNotFound: {
            generateMfaTokenStatus.errortype = GenerateMfaTokenStatusErrorType.ResourceNotFound;
            generateMfaTokenStatus.error = response.errorMessage;
            break;
          }
          default: {
            generateMfaTokenStatus.errortype = GenerateMfaTokenStatusErrorType.GenericServerError;
            break;
          }
        }
      }
      else {
        generateMfaTokenStatus.errortype = GenerateMfaTokenStatusErrorType.GenericServerError;
      }
    }
    return of(generateMfaTokenStatus);
  }

  private getMfaTokenSuccess(result: any): Observable<any> {
    return of(result);
  }
  public generateMultiFactorToken(email: string): Observable<GenerateMultiFactorTokenStatus> {
    let challengeSubmssionRequest = new ChallengeSubmssionRequest();
    let transaction_id: string = uuidv4();
    let mfa_app_id:string;
    this.store.select(state => state.EnvironmentConfig.MFA_APP_ID).subscribe(x => mfa_app_id = x);
    const mfaAppId: string = mfa_app_id;   
    challengeSubmssionRequest.locale = this.languageService.getCurrentLanguage().toUpperCase();
    challengeSubmssionRequest.primarymfafactortype= Constants.MfaEmailAddress;
    challengeSubmssionRequest.primarymfadeliverychannel= Constants.MfaEmail;
    challengeSubmssionRequest.primarymfafactorvalue= email;
    challengeSubmssionRequest.transactionid = transaction_id;

    return this.client.challengeSubmission(mfaAppId, transaction_id, challengeSubmssionRequest)
      .pipe(mergeMap((response) => { return this.afterGenerateMultiFactorTokenRequestPostSuccess(response, transaction_id); })
      ,catchError((error: any) => { return this.afterGenerateMultiFactorTokenRequestPostFailure(error); }));

  }

  private afterGenerateMultiFactorTokenRequestPostSuccess(result: any, transactionId: string): Observable<GenerateMultiFactorTokenStatus> {
    let generateMultiFactorTokenStatus = new GenerateMultiFactorTokenStatus();
    generateMultiFactorTokenStatus.transactionId = transactionId;
    return of(generateMultiFactorTokenStatus);
  }

  private afterGenerateMultiFactorTokenRequestPostFailure(error: any): Observable<GenerateMultiFactorTokenStatus> {
    let generateMultiFactorTokenStatus = new GenerateMultiFactorTokenStatus();
    generateMultiFactorTokenStatus.error = true;
    if (error != undefined) {
      let response = JSON.parse(error);
      let faultType = response ? response.errorType : null;
      if (faultType) {
        switch (faultType) {
          case FaultCodes.ResourceNotFound: {
            generateMultiFactorTokenStatus.errortype = GenerateMultiFactorTokenStatusErrorType.ResourceNotFound;
            generateMultiFactorTokenStatus.error = response.errorMessage;
            break;
          }
          default: {
            generateMultiFactorTokenStatus.errortype = GenerateMultiFactorTokenStatusErrorType.GenericServerError;
            break;
          }
        }
      }
      else {
        generateMultiFactorTokenStatus.errortype = GenerateMultiFactorTokenStatusErrorType.GenericServerError;
      }
    }
    return of(generateMultiFactorTokenStatus);
  }
}
