import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

// import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HomeModule} from './home/home.module';
import { AuthGuard } from '../../../_shared/_gaurd/auth.guard';

import {UserService} from '../../../_shared/shared/_helper-services/user.service';
import {RouterService} from '../../../_shared/shared/_helper-services/router.service';
import {GuardService} from '../../../_shared/shared/_helper-services/guard.service';
import {UrlBuilderService} from '../../../_shared/shared/_helper-services/url-builder.service';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from '../../../_shared/shared/shared.module';
import { IAppState, rootReducer, INITIAL_STATE } from '../../../_shared/shared/store/app.store';
import { EnvironmentSpecificService } from "./configuration/environment-specific-service";
import { ApplicationConfig } from '../../../_shared/_models/application-config';
import { ApplicationConfigActions } from '../../../_shared/shared/store/reducers/application-config.reducer';
import { EnvironmentConfigActions } from '../../../_shared/shared/store/reducers/environment-config.reducer';
import { StorageService } from '../../../_shared/shared/_helper-services/storage.service';
import { LanguageService } from '../../../_shared/shared/_helper-services/language.service';
import { FeatureToggleModule } from '../../../_shared/feature/feature-toggle/feature-toggle.module';
import { SsoModule } from '../../../_shared/feature/sso/sso.module';
import { API_BASE_URL, AuthServiceClient, ClientLogWriterClient } from '../../../_shared/core/gateway-api';
import { Client } from '../../../_shared/core/mfa-token-api';
import { BaseComponentModule } from '../../../_shared/core/basecomponent/base-component.module';
import { ClientLogWriterService } from "../../../_shared/_web-services/client-log-writer.service";
import { PathNotFoundComponent } from './path-not-found/path-not-found.component';
import { ParentActivityIdActions } from '../../../_shared/shared/store/reducers/parent-activity-id.reducer';
import { rhmRouting } from './app-routing.module';
import { SelectivePreloadingStrategy } from '../../../_shared/core/selective-preloading-strategy';
import { UserActivityIdActions } from '../../../_shared/shared/store/reducers/user-activityId.reducer';
import { AuthorizationInterceptor } from '../../../_shared/core/interceptors/authorization.interceptor';
import { IdleService } from '../../../_shared/shared/_helper-services/idle.service';
import { DialogService } from '../../../_shared/shared/_helper-services/dialog.service';
import { NgIdleModule } from '@ng-idle/core';
import { UserIsTimedOutActions } from '../../../_shared/shared/store/reducers/user-is-timed-out.reducer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StorageHelperService } from '../../../_shared/shared/_helper-services/storage-helper.service';
import { AuthService } from '../../../_shared/_web-services/auth.service';
import { ErrorMessagingService } from '../../../_shared/shared/_helper-services/error-messaging.service';
import { MfaHelperService } from '../../../_shared/feature/authentication/mfa/_services/mfa-helper.service';
import { Store, StoreModule } from '@ngrx/store';
import { DataLoadedActions } from '../../../_shared/shared/store/reducers/dataloaded.reducer';
@NgModule({
  declarations: [
    AppComponent,
    PathNotFoundComponent,
  ],
  imports: [
    rhmRouting,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    HomeModule,
    SharedModule,
    FeatureToggleModule,
    BaseComponentModule,   
    NgIdleModule.forRoot(),
    StoreModule.forRoot (rootReducer, {
      runtimeChecks: {
      strictStateImmutability: false,
      strictActionImmutability: false
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SsoModule
  ],
  providers: [SelectivePreloadingStrategy, 
    UserService,RouterService,GuardService,UrlBuilderService,StorageService,LanguageService, 
    ClientLogWriterService,ClientLogWriterClient, TranslateService,EnvironmentSpecificService,
    ParentActivityIdActions, IdleService,DialogService,
    UserActivityIdActions,
    UserIsTimedOutActions,
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializerLoaderFactory,
      deps: [EnvironmentSpecificService, UserService, Store],
      multi: true
    },
    {
      provide: API_BASE_URL,
      useFactory: BaseUrlLoaderFactory,
      deps: [Store],
    },
   ApplicationConfigActions,
   EnvironmentConfigActions,
   {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthorizationInterceptor,
    multi: true
  },
  StorageHelperService,
  AuthService,
  AuthServiceClient,
  Client,
  ErrorMessagingService,
  MfaHelperService,
  DataLoadedActions 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(private translate: TranslateService) {

    translate.addLangs(["us-bmw-en", "us-bmw-es"]);
    translate.setDefaultLang('us-bmw-en');
    translate.use('us-bmw-en');
  }
}

export function HttpLoaderFactory(http: HttpClient) {

  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function AppInitializerLoaderFactory(environmentSpecificService: EnvironmentSpecificService, userService: UserService, store: Store<IAppState>) {

  //store.configureStore(rootReducer, INITIAL_STATE);
  var appConfig: ApplicationConfig = environmentSpecificService.loadApplicationConfig();
  userService.setApplicationConfig(appConfig);
  return () => environmentSpecificService.loadEnvironment()
    .subscribe(e => {
      userService.setEnvironmentConfig(e);
    }
    );
}

export function BaseUrlLoaderFactory(store: Store<IAppState>) {
  let gatewayApiBaseUrl: string | undefined;
  store.select(state => state.EnvironmentConfig?.GATEWAY_API_BASE_URL).subscribe(x => gatewayApiBaseUrl = x)
  return gatewayApiBaseUrl;
}