export class EnvironmentConfig {
  PRODUCTION: boolean;
  GATEWAY_API_BASE_URL: string;
  MFA_API_BASE_URL: string;
  CONSOLE_LOGGING_ENABLED: boolean;
  APP_LOGGING_ENABLED: boolean;
  IDLE_SESSION: number;
  TIME_OUT_SESSION: number;
  MFA_AUTH_COOKIE_EXPIRATION_DAYS: number;
  SSO_REDIRECT_URI: string;
  SSO_POST_LOGOUT_URI: string;
  REPO_LAUNCH_URL: string;
  AUTH_HUB_SSO_URL: string;
  SSO_LOGOUT_REDIRECT_URI: string;
  DEV_CONSOLE_ENABLED: boolean;
  DISABLED_FEATURES: string[];
  ENABLED_FEATURES: string[];
  SSO: boolean;
  MFA_APP_ID: string;
  CLIENT_ID: string;
  WEB_EAM_LOGOUT_URL: string;

}

export class AnalyticsConfig {
  BASE_DOMAIN: string;
  CUSTOM_LINK_TRACKING: boolean;
  DATALAYER_NAME: string;
  DEBUG: boolean;
  EMBED_SCRIPT_URL: string;
  SUB_DOMAIN: string;
}

export class DevConsoleConfig {
  DEV_CONSOLE_CONNECTEDAPP_URL: string;
  DEV_CONSOLE_NGD_URL: string;
  DEV_CONSOLE_UPAY_DEEP_LINK: string;
}

export class CarlabsConfig {
  SCRIPT_URL: string;
  DATA_BOT_ID: string;
}
