// Modules
import { NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';


import {
  MatCheckboxModule
} from '@angular/material/checkbox';
import {
  MatSelectModule
} from '@angular/material/select';
import {
  MatDatepickerModule 
} from '@angular/material/datepicker';
import {
  MatNativeDateModule
} from '@angular/material/core';
import {
  MatTooltipModule
} from '@angular/material/tooltip';
import {
  MatButtonModule
} from '@angular/material/button';
import {
  MatExpansionModule
} from '@angular/material/expansion';
import {
  MatMenuModule
} from '@angular/material/menu';
import {
    MatRadioModule
} from '@angular/material/radio';
import {MatProgressBarModule} from '@angular/material/progress-bar';

import { TranslateModule } from '@ngx-translate/core';
import { SharedRoutingModule } from './shared-routing.module';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Services
import { RouterService } from './_helper-services/router.service';
// Service Clients
import { HasLoggedInActions } from './store/reducers/has-logged-in.reducer';
import { UserInactivityDialog } from './_components/dialogs/user-inactivity-dialog/user-inactivity-dialog.component';
import { UserInactivityDialogButtons } from './_components/dialogs/user-inactivity-dialog-buttons/user-inactivity-dialog-buttons.component';
import { StorageHelperService } from './_helper-services/storage-helper.service';
import { MaskService } from './_helper-services/mask.service';
import { ValidatorService } from '../_validators/validator.service';
import { WindowService } from './_helper-services/window.service';
import { InputLengthDirective } from '../_directives/input-length.directive';
import { SpinnerComponent } from './_components/spinner/spinner.component';



@NgModule({
  imports: [
    SharedRoutingModule,
    CommonModule,
    TranslateModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatButtonModule,
    MatExpansionModule,
    MatMenuModule,
    FormsModule,
    MatRadioModule,
    MatDialogModule,
    MatProgressBarModule
  ],
  declarations: [
    UserInactivityDialog,
    UserInactivityDialogButtons,
    InputLengthDirective,
    SpinnerComponent
    ],
  exports: [
    
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatButtonModule,
    MatExpansionModule,
    MatMenuModule,
    MatRadioModule,    
    MatProgressBarModule,   
    TranslateModule,
    InputLengthDirective,
    SpinnerComponent
    ],
  providers: [    
    RouterService,
    HasLoggedInActions,
    StorageHelperService,
    MaskService,
    ValidatorService,
    WindowService,
    ]
})
export class SharedModule {
  @Input() collapsedHeight: string;
  @Input() expandedHeight: string;
}
