import { Injectable } from '@angular/core';
import { Reducer } from 'redux';
import { BooleanModel } from '../../../_models/boolean.model';
import { FSA, isError, isFSA } from 'flux-standard-action';

import { tassign } from 'tassign';


export class HasLoggedInActions
{
    static SET_HAS_LOGGED_IN: string = 'SET_HAS_LOGGED_IN';

    setHasLoggedIn(value: boolean): FSA<string, boolean, null>{
        return {
            type: HasLoggedInActions.SET_HAS_LOGGED_IN,
            payload: value,
            meta: null
        };
    }

}

@Injectable()
export class HasLoggedInReducers
{
    static HasLoggedInReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(false), action: FSA<string, boolean, void>) => {
        switch(action.type){
            case HasLoggedInActions.SET_HAS_LOGGED_IN:
                return tassign(state, new BooleanModel(action.payload));
            default:
                return state;
        }
    }
}
