import { Injectable } from '@angular/core';
import { IAppState } from "../store/app.store";
import { routes } from "./router.service";
import { Constants } from '../constants';
import { EnvironmentConfig } from "../../_models/environment-config";
import { ApplicationConfig } from '../../_models/application-config';
import { LanguageService } from '../../shared/_helper-services/language.service';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class UrlBuilderService {
  public currentRoute: routes;
  public envConfig: EnvironmentConfig;
  public appConfig: ApplicationConfig;

  constructor(
    private store: Store<IAppState>,
    private languageService: LanguageService
    ){}

  public getWebEamLogoutRedirectUrl(idToken: string): string {
    this.store.select(state => state.EnvironmentConfig).subscribe(x => this.envConfig = x);
    this.store.select(state => state.ApplicationConfig).subscribe(x => this.appConfig = x);
    let idParam = this.getRouteParameter(Constants.SsoAuthIdToken, idToken);
    let postLogoutUri = this.getRouteParameter(Constants.SsoAuthPostLogoutUri, this.envConfig.SSO_POST_LOGOUT_URI, true);
    let url = this.envConfig.AUTH_HUB_SSO_URL.replace(Constants.SsoAuthorize,"").concat(Constants.SsoAuthHubLogoutRoute, Constants.UrlParametersPrefix, 
      idParam, postLogoutUri);
    return url;
  }

  public getWebEamRedirectUrl(state: string): string {
    this.store.select(state => state.EnvironmentConfig).subscribe(x => this.envConfig = x);
    
    let domainUrl = this.envConfig.AUTH_HUB_SSO_URL.concat(Constants.UrlParametersPrefix);
    let clientIdParameter = this.getRouteParameter(Constants.SsoAuthHubRouteClientIdParameterName, this.envConfig.CLIENT_ID);
    let scopeParameter = this.getRouteParameter(Constants.SsoAuthHubRouteScopeParameterName, Constants.SsoAuthHubRouteScopeParameterValue);
    let responseTypeParameter = this.getRouteParameter(Constants.SsoAuthHubRouteResponseTypeParameterName, Constants.SsoAuthHubRouteResponseTypeParameterValue);
    let redirectUriParameter = this.getRouteParameter(Constants.SsoAuthHubRouteRedirectUriParameterName, encodeURI(this.envConfig.SSO_REDIRECT_URI));
    let ssoLogoutUri = this.getRouteParameter(Constants.SsoAuthHubRouteLogoutUriParameterName, encodeURI(this.envConfig.SSO_LOGOUT_REDIRECT_URI));
    let langParameter = this.getRouteParameter(Constants.LanguageParameterName, this.languageService.getCurrentLanguage());
    let stateParameter = this.getRouteParameter(Constants.SsoAuthHubRouteStateParameterName, encodeURIComponent(state), true);
    return domainUrl.concat(clientIdParameter, scopeParameter, responseTypeParameter, redirectUriParameter, ssoLogoutUri, langParameter, stateParameter)
  }

  private getRouteParameter(parameterName: string, parameterValue: string, isLastParameter?: boolean): string {
    return isLastParameter
      ? parameterName.concat(Constants.UrlParameterNameAndValueConcat, parameterValue)
      : parameterName.concat(Constants.UrlParameterNameAndValueConcat, parameterValue, Constants.UrlParametersConcat);
  }
}
