export class FaultCodes
{
    public static BMWFSAM_Services_IdentityAndAccessManagement_UserNotFound_V201109_UserNotFoundFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.UserNotFound.V201109.UserNotFoundFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_UsernameDisabled_V201109_UsernameDisabledFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.UsernameDisabled.V201109.UsernameDisabledFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_AuthenticationFailed_V201109_AuthenticationFailedFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.AuthenticationFailed.V201109.AuthenticationFailedFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_UserAccountExpired_V201109_UserAccountExpiredFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.UserAccountExpired.V201109.UserAccountExpiredFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_UserAccountPasswordLocked_V201109_UserAccountPasswordLockedFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.UserAccountPasswordLocked.V201109.UserAccountPasswordLockedFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_DataValidation_V201112_DataValidationFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.DataValidation.V201112.DataValidationFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_UserAccountNotActivated_V201109_UserAccountNotActivatedFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.UserAccountNotActivated.V201109.UserAccountNotActivatedFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_UserAlreadyRegisteredFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.UserAlreadyRegisteredFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_InvalidFirstNameFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.InvalidFirstNameFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_InvalidLastNameFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.InvalidLastNameFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_InvalidPasswordFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.InvalidPasswordFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_InvalidUserNameFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.InvalidUserNameFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_InvalidClientI_V201112_InvalidClientIdFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.InvalidClientId.V201112.InvalidClientIdFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_ResendActivationLimitExceeded_V201112_ResendActivationLimitExceededFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.ResendActivationLimitExceeded.V201112.ResendActivationLimitExceededFault";
    public static BMWFSAM_Services_IdentityAndAccessManagement_InvalidAccountState_V201112_InvalidAccountStateFault: string = "BMWFSAM.Services.IdentityAndAccessManagement.InvalidAccountState.V201112.InvalidAccountStateFault";


  public static CLIENT_AUTHENTICATION_FAILED: string = "Client authentication failed.";
  public static ERROR_OCCURRED: string = "Error occurred.";
  public static INVALID_CREDENTIALS: string = "Invalid Credentials";
  public static Bad_Request: string = "BAD REQUEST";
  public static UnAuthorized: string = "UNAUTHORIZED";
  public static ResourceNotFound: string = "ResourceNotFound";
}
