import { Observable, catchError, map, mergeMap, of, throwError } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase} from '@angular/common/http';
import { IAppState } from '../shared/store/app.store';
import { Store } from '@ngrx/store';


@Injectable()
export class Client {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(@Inject(HttpClient) http: HttpClient, private store: Store<IAppState>) {
        this.http = http;
        let baseUrl: string | undefined;
        store.select(state => state.EnvironmentConfig?.MFA_API_BASE_URL).subscribe(x => baseUrl = x);      
        this.baseUrl = baseUrl;
    }

    /**
     * @transaction_id A code to track API calls end to end.
     */
    challengeSubmission(clientid: string, transaction_id: string, generated: ChallengeSubmssionRequest): Observable<any> {
        let url_ = this.baseUrl + "/mfa/client/{clientid}/challenge-submission";
        if (clientid === undefined || clientid === null)
            throw new Error("The parameter 'clientid' must be defined.");
        url_ = url_.replace("{clientid}", encodeURIComponent("" + clientid)); 
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(generated);
        
        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
              "transaction_id": transaction_id,
              "Content-Type": "application/json",
              "Accept": "application/json"
            })
        };

        return this.http.request("post",url_, options_).pipe(map((_response: any) => {
            return this.processChallengeSubmission(_response);
        }),catchError((_response: any) => {
            if (_response instanceof HttpResponseBase) {
                try {
                    return this.processChallengeSubmission(_response);
                } catch (e) {
                    return <Observable<null>><any>throwError(e);
                }
            } else
                return <Observable<null>><any>throwError(_response);
        }));
    }

    protected processChallengeSubmission(_response: HttpResponseBase): Observable<any> {
        const _status = _response.status; 
        const responseBlob =
        _response instanceof HttpResponse ? _response.body :
          (<any>_response).error instanceof Blob ? (<any>_response).error : undefined;
  
      let _headers: any = {}; if (_response.headers) { for (let key of _response.headers.keys()) { _headers[key] = _response.headers.get(key); } };
      
        if (_status === 204) {
            return blobToText(responseBlob).pipe(mergeMap(_responseText => {
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                return of(resultData200);
              }));
        } else if (_status !== 200 && _status !== 204) {
            return blobToText(responseBlob).pipe(mergeMap(_responseText => {
                return throwException("An unexpected server error occurred.", _status, _responseText, _headers);
            }));
        }
        return of(null);
    }

     /**
     * @transaction_id A code to track API calls end to end.
     */
      challengeValidation(clientid: string, transaction_id: string, generated: ChallengeValidationRequest): Observable<any> {
        let url_ = this.baseUrl + "/mfa/client/{clientid}/challenge-validation";
        if (clientid === undefined || clientid === null)
            throw new Error("The parameter 'clientid' must be defined.");
        url_ = url_.replace("{clientid}", encodeURIComponent("" + clientid)); 
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(generated);
        
        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
              "transaction_id": transaction_id,
              "Content-Type": "application/json",
              "Accept": "application/json"
            }),
            credentials: 'same-origin'
          };
      
          return this.http.request("post", url_, options_).pipe(mergeMap((response_: any) => {
            return this.processChallengeValidation(response_);
          }),catchError((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processChallengeValidation(response_);
                } catch (e) {
                    return <Observable<null>><any>throwError(e);
                }
            } else
                return <Observable<null>><any>throwError(response_);
        }));
    }

    protected processChallengeValidation(_response: HttpResponseBase): Observable<any> {
        const _status = _response.status; 
        const responseBlob =
        _response instanceof HttpResponse ? _response.body :
          (<any>_response).error instanceof Blob ? (<any>_response).error : undefined;

        let _headers: any = {}; if (_response.headers) { for (let key of _response.headers.keys()) { _headers[key] = _response.headers.get(key); } };

        if (_status === 204) {
            return of(null);
        } else if (_status !== 200 && _status !== 204) {
            return blobToText(responseBlob).pipe(mergeMap(_responseText => {
                return throwException("An unexpected server error occurred.", _status, _responseText, _headers);
            }));
        }
        return of(null);
    }
}

/** This resource submits the request to generate and send an MFA token based on primary factor */
export class ChallengeSubmssionRequest implements IChallengeSubmssionRequest {
    /** language to send challenge. Values supported "EN" */
    locale: string;
    /** unique quid to be sent with request to be resent with validation */
    transactionid: string;
    /** primary mfa send factor. Values include "EmailAddress", "PhoneNumber" */
    primarymfafactortype: string;
    /** Additional information about the log site activity. Used in Dashboard and Offers. */
    primarymfadeliverychannel: string;
    /** primary mfa value to connect for given channel type. values include email address or phone number in format (+x(xxx)xxx-xxxx) */
    primarymfafactorvalue: string;
    /** secondary mfa send factor. Values include "EmailAddress", "PhoneNumber" */
    secondarymfafactortype?: string;
    /** secondary mfa channel. Values include "Email", "SMS" */
    secondarymfadeliverychannel?: string;
    /** secondary mfa value to connect for given channel type. values include email address or phone number in format (+x(xxx)xxx-xxxx) */
    secondarymfafactorvalue?: string;

    constructor(data?: IChallengeSubmssionRequest) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(data?: any) {
        if (data) {
            this.locale = data["locale"] !== undefined ? data["locale"] : <any>null;
            this.transactionid = data["transactionid"] !== undefined ? data["transactionid"] : <any>null;
            this.primarymfafactortype = data["primarymfafactortype"] !== undefined ? data["primarymfafactortype"] : <any>null;
            this.primarymfadeliverychannel = data["primarymfadeliverychannel"] !== undefined ? data["primarymfadeliverychannel"] : <any>null;
            this.primarymfafactorvalue = data["primarymfafactorvalue"] !== undefined ? data["primarymfafactorvalue"] : <any>null;
            this.secondarymfafactortype = data["secondarymfafactortype"] !== undefined ? data["secondarymfafactortype"] : <any>null;
            this.secondarymfadeliverychannel = data["secondarymfadeliverychannel"] !== undefined ? data["secondarymfadeliverychannel"] : <any>null;
            this.secondarymfafactorvalue = data["secondarymfafactorvalue"] !== undefined ? data["secondarymfafactorvalue"] : <any>null;
        }
    }

    static fromJS(data: any): ChallengeSubmssionRequest {
        let result = new ChallengeSubmssionRequest();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = data ? data : {};
        data["locale"] = this.locale !== undefined ? this.locale : <any>null;
        data["transactionid"] = this.transactionid !== undefined ? this.transactionid : <any>null;
        data["primarymfafactortype"] = this.primarymfafactortype !== undefined ? this.primarymfafactortype : <any>null;
        data["primarymfadeliverychannel"] = this.primarymfadeliverychannel !== undefined ? this.primarymfadeliverychannel : <any>null;
        data["primarymfafactorvalue"] = this.primarymfafactorvalue !== undefined ? this.primarymfafactorvalue : <any>null;
        data["secondarymfafactortype"] = this.secondarymfafactortype !== undefined ? this.secondarymfafactortype : <any>null;
        data["secondarymfadeliverychannel"] = this.secondarymfadeliverychannel !== undefined ? this.secondarymfadeliverychannel : <any>null;
        data["secondarymfafactorvalue"] = this.secondarymfafactorvalue !== undefined ? this.secondarymfafactorvalue : <any>null;
        return data; 
    }
}

/** This resource submits the request to generate and send an MFA token based on primary factor */
export interface IChallengeSubmssionRequest {
    /** language to send challenge. Values supported "EN" */
    locale: string;
    /** unique quid to be sent with request to be resent with validation */
    transactionid: string;
    /** primary mfa send factor. Values include "EmailAddress", "PhoneNumber" */
    primarymfafactortype: string;
    /** Additional information about the log site activity. Used in Dashboard and Offers. */
    primarymfadeliverychannel: string;
    /** primary mfa value to connect for given channel type. values include email address or phone number in format (+x(xxx)xxx-xxxx) */
    primarymfafactorvalue: string;
    /** secondary mfa send factor. Values include "EmailAddress", "PhoneNumber" */
    secondarymfafactortype?: string;
    /** secondary mfa channel. Values include "Email", "SMS" */
    secondarymfadeliverychannel?: string;
    /** secondary mfa value to connect for given channel type. values include email address or phone number in format (+x(xxx)xxx-xxxx) */
    secondarymfafactorvalue?: string;
}

/** This resource validates MFA token based on primary factor */
export class ChallengeValidationRequest implements IChallengeValidationRequest {
    /** language to send challenge. Values supported "EN" */
    locale: string;
    /** unique quid to be sent with request to be resent with validation */
    transactionid: string;
    /** token value to be sent for verification */
    mfatoken: string;
    /** primary mfa send factor. Values include "EmailAddress", "PhoneNumber" */
    primarymfafactortype: string;
    /** Additional information about the log site activity. Used in Dashboard and Offers. */
    primarymfadeliverychannel: string;
    /** primary mfa value to connect for given channel type. values include email address or phone number in format (+x(xxx)xxx-xxxx) */
    primarymfafactorvalue: string;
    /** secondary mfa send factor. Values include "EmailAddress", "PhoneNumber" */
    secondarymfafactortype?: string;
    /** secondary mfa channel. Values include "Email", "SMS" */
    secondarymfadeliverychannel?: string;
    /** secondary mfa value to connect for given channel type. values include email address or phone number in format (+x(xxx)xxx-xxxx) */
    secondarymfafactorvalue?: string;

    constructor(data?: IChallengeValidationRequest) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(data?: any) {
        if (data) {
            this.locale = data["locale"] !== undefined ? data["locale"] : <any>null;
            this.transactionid = data["transactionid"] !== undefined ? data["transactionid"] : <any>null;
            this.mfatoken = data["mfatoken"] !== undefined ? data["mfatoken"] : <any>null;
            this.primarymfafactortype = data["primarymfafactortype"] !== undefined ? data["primarymfafactortype"] : <any>null;
            this.primarymfadeliverychannel = data["primarymfadeliverychannel"] !== undefined ? data["primarymfadeliverychannel"] : <any>null;
            this.primarymfafactorvalue = data["primarymfafactorvalue"] !== undefined ? data["primarymfafactorvalue"] : <any>null;
            this.secondarymfafactortype = data["secondarymfafactortype"] !== undefined ? data["secondarymfafactortype"] : <any>null;
            this.secondarymfadeliverychannel = data["secondarymfadeliverychannel"] !== undefined ? data["secondarymfadeliverychannel"] : <any>null;
            this.secondarymfafactorvalue = data["secondarymfafactorvalue"] !== undefined ? data["secondarymfafactorvalue"] : <any>null;
        }
    }

    static fromJS(data: any): ChallengeValidationRequest {
        let result = new ChallengeValidationRequest();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = data ? data : {};
        data["locale"] = this.locale !== undefined ? this.locale : <any>null;
        data["transactionid"] = this.transactionid !== undefined ? this.transactionid : <any>null;
        data["mfatoken"] = this.mfatoken !== undefined ? this.mfatoken : <any>null;
        data["primarymfafactortype"] = this.primarymfafactortype !== undefined ? this.primarymfafactortype : <any>null;
        data["primarymfadeliverychannel"] = this.primarymfadeliverychannel !== undefined ? this.primarymfadeliverychannel : <any>null;
        data["primarymfafactorvalue"] = this.primarymfafactorvalue !== undefined ? this.primarymfafactorvalue : <any>null;
        data["secondarymfafactortype"] = this.secondarymfafactortype !== undefined ? this.secondarymfafactortype : <any>null;
        data["secondarymfadeliverychannel"] = this.secondarymfadeliverychannel !== undefined ? this.secondarymfadeliverychannel : <any>null;
        data["secondarymfafactorvalue"] = this.secondarymfafactorvalue !== undefined ? this.secondarymfafactorvalue : <any>null;
        return data; 
    }
}

/** This resource validates MFA token based on primary factor */
export interface IChallengeValidationRequest {
    /** language to send challenge. Values supported "EN" */
    locale: string;
    /** unique quid to be sent with request to be resent with validation */
    transactionid: string;
    /** token value to be sent for verification */
    mfatoken: string;
    /** primary mfa send factor. Values include "EmailAddress", "PhoneNumber" */
    primarymfafactortype: string;
    /** Additional information about the log site activity. Used in Dashboard and Offers. */
    primarymfadeliverychannel: string;
    /** primary mfa value to connect for given channel type. values include email address or phone number in format (+x(xxx)xxx-xxxx) */
    primarymfafactorvalue: string;
    /** secondary mfa send factor. Values include "EmailAddress", "PhoneNumber" */
    secondarymfafactortype?: string;
    /** secondary mfa channel. Values include "Email", "SMS" */
    secondarymfadeliverychannel?: string;
    /** secondary mfa value to connect for given channel type. values include email address or phone number in format (+x(xxx)xxx-xxxx) */
    secondarymfafactorvalue?: string;
}

export class SwaggerException extends Error {
    message: string;
    status: number; 
    response: string; 
    result: any; 

    constructor(message: string, status: number, response: string, result: any) {
        super();

        this.message = message;
        this.status = status;
        this.response = response;
        this.result = result;
    }
}

function throwException(message: string, status: number, response: string, result?: any): Observable<any> {
    if(response !== null && response !== undefined)
        return throwError(response);
    else
        return throwError(new SwaggerException(message, status, response, null));
}

function blobToText(blob: Blob): Observable<string> {
    return new Observable<string>((observer: any) => { 
        let reader = new FileReader(); 
        reader.onload = function() { 
            observer.next(this.result);
            observer.complete();
        }
        reader.readAsText(blob); 
    });
}