import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ClientLogWriterClient, Anonymous, BeginActivityRequest, ClientLogRequest } from "../core/gateway-api";

@Injectable()
export class ClientLogWriterService {

  constructor(private clientLogWriterClient: ClientLogWriterClient) {
  }

  public currentLevel(): Promise<any> {
    return this.clientLogWriterClient.currentLevel()
      .toPromise();
  }

  public beginNewActivity(beginActivityRequest: BeginActivityRequest): Promise<any> {
    return this.clientLogWriterClient.beginNewActivity(beginActivityRequest)
      .toPromise();
  }

  public log(clientLogRequest: ClientLogRequest) : Promise<void>
  {
    return this.clientLogWriterClient.log(clientLogRequest).toPromise();
  }
}
