
<div class="page-content">
  <div class="page-loading" style="display: none;"></div>
  <div class="no-darken" style="display:none"></div>
  <div class="general-error-service-error"></div>
  <div class="errorWrapper">
    <div class="bannerWrapper">
      <div class="banner-content">
        <h1>{{'rhm.page-not-found.somethings-missing-msg' | translate}}</h1>
        <p>{{'rhm.page-not-found.cant-find-msg' | translate}}<br><br>{{'rhm.page-not-found.return-to-msg' | translate}}<a routerLink="/home">{{'rhm.page-not-found.btn-home' | translate}}</a></p>
      </div>
    </div>
  </div>
</div>
