import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { UserService } from '../../shared/_helper-services/user.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(private userService: UserService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Don't override authorization header for this request

    if (this.userService.isAuthenticated()) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this.userService.getUserBearerToken()
        }
      });
    }
    return next.handle(request);

  }
}
