import { NgModule } from '@angular/core';
import { ActivityLoggingFactory } from './features/logging/logging.factory';


@NgModule({
  imports: [],
  declarations: [
    
  ],
  providers: [ActivityLoggingFactory],
  exports: []
})
export class BaseComponentModule {}
